/*
Icon classes can be used entirely standalone. They are named after their original file names.

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.icon-png-account {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -106px -506px;
  width: 36px;
  height: 36px;
}
.icon-png-arrowDown {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -222px -506px;
  width: 20px;
  height: 11px;
}
.icon-png-authorize {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -535px -224px;
  width: 90px;
  height: 72px;
}
.icon-png-cart {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -480px -352px;
  width: 43px;
  height: 32px;
}
.icon-png-check-blue {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -187px -506px;
  width: 25px;
  height: 19px;
}
.icon-png-check-mark {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -611px -306px;
  width: 9px;
  height: 7px;
}
.icon-png-check {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -152px -506px;
  width: 25px;
  height: 19px;
}
.icon-png-check2 {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -350px -356px;
  width: 29px;
  height: 30px;
}
.icon-png-check3 {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -298px -402px;
  width: 59px;
  height: 59px;
}
.icon-png-check4 {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -389px -356px;
  width: 20px;
  height: 20px;
}
.icon-png-checkGreen {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -294px -122px;
  width: 23px;
  height: 23px;
}
.icon-png-close {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -327px -122px;
  width: 23px;
  height: 22px;
}
.icon-png-dasboard2 — копия {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -233px -284px;
  width: 107px;
  height: 106px;
}
.icon-png-dashboard {
  background-image: url(../images/pngicons/sprite.png);
  background-position: 0px -506px;
  width: 49px;
  height: 49px;
}
.icon-png-dashboard2 {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -116px -284px;
  width: 107px;
  height: 106px;
}
.icon-png-facebook {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -367px -402px;
  width: 50px;
  height: 50px;
}
.icon-png-how {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -360px -122px;
  width: 24px;
  height: 21px;
}
.icon-png-invoice {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -352px -158px;
  width: 54px;
  height: 54px;
}
.icon-png-invoice2 — копия {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -419px -116px;
  width: 106px;
  height: 106px;
}
.icon-png-invoice2 {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -419px 0px;
  width: 106px;
  height: 106px;
}
.icon-png-linkedin {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -427px -402px;
  width: 49px;
  height: 49px;
}
.icon-png-maestro {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -535px -463px;
  width: 51px;
  height: 32px;
}
.icon-png-management {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -350px -284px;
  width: 58px;
  height: 62px;
}
.icon-png-management2 — копия {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -118px -158px;
  width: 108px;
  height: 116px;
}
.icon-png-management2 {
  background-image: url(../images/pngicons/sprite.png);
  background-position: 0px -158px;
  width: 108px;
  height: 116px;
}
.icon-png-mastercard {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -419px -352px;
  width: 51px;
  height: 32px;
}
.icon-png-nextArrow {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -59px -506px;
  width: 37px;
  height: 37px;
}
.icon-png-payment {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -535px -394px;
  width: 81px;
  height: 59px;
}
.icon-png-payment2 {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -259px 0px;
  width: 150px;
  height: 112px;
}
.icon-png-pick {
  background-image: url(../images/pngicons/sprite.png);
  background-position: 0px 0px;
  width: 249px;
  height: 148px;
}
.icon-png-prevArrow {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -486px -402px;
  width: 37px;
  height: 37px;
}
.icon-png-price {
  background-image: url(../images/pngicons/sprite.png);
  background-position: 0px -402px;
  width: 110px;
  height: 94px;
}
.icon-png-search {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -259px -122px;
  width: 25px;
  height: 26px;
}
.icon-png-shipping {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -120px -402px;
  width: 96px;
  height: 94px;
}
.icon-png-suppliers2 — копия {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -236px -158px;
  width: 106px;
  height: 108px;
}
.icon-png-suppliers2 {
  background-image: url(../images/pngicons/sprite.png);
  background-position: 0px -284px;
  width: 106px;
  height: 108px;
}
.icon-png-support {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -535px -120px;
  width: 89px;
  height: 94px;
}
.icon-png-tooth {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -226px -402px;
  width: 62px;
  height: 76px;
}
.icon-png-tooth2 — копия {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -535px 0px;
  width: 90px;
  height: 110px;
}
.icon-png-tooth2 {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -419px -232px;
  width: 90px;
  height: 110px;
}
.icon-png-tracker {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -535px -306px;
  width: 66px;
  height: 78px;
}
.icon-png-visa {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -352px -222px;
  width: 51px;
  height: 32px;
}
.icon-png-wishlist {
  background-image: url(../images/pngicons/sprite.png);
  background-position: -596px -463px;
  width: 29px;
  height: 32px;
}
