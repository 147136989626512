@import './common/mixins';
@import './common/fonts';
@import "../../../node_modules/slick-carousel/slick/slick-theme.scss";
@import "../../../node_modules/slick-carousel/slick/slick.scss";
@import "../../../node_modules/normalize.css/normalize";
@import './common/sprite';
@import './common/spritepng';
@import './components/header';
@import './components/header_2';

*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0; }

.block-content {
  pointer-events: none; }

// icalendar iframe
.icalendar {
  position: fixed;
  display: none;
  top: 50%;
  left: 50%;
  background: #ffffff;
  max-width: 600px;
  width: 85%;
  height: 85%;
  z-index: 1001;
  max-height: 600px;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
  #ifrcalendar {
    height: 100%;
    width: 100%; }
  &__close {
    border: 0;
    outline: 0;
    background-color: transparent;
    background: transparent;
    position: absolute;
    right: -20px;
    top: -20px;
    cursor: pointer; } }

#drop-elem {
  z-index: 1000;
  max-width: 550px;
  width: 100%;
  backface-visibility: hidden;
  line-height: 1.4;
  position: fixed;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.3);
  cursor: default;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #c5c5c5;
  // transition: all .2s ease
  height: 90%;
  max-height: 365px;
  overflow: hidden;
  z-index: 1001;
  @include r(767) {
    width: 90%;
    max-height: 435px;
    border-radius: 50px 0 0 0;
    // bottom: 0
    // left: 0
    // right: 0
    // min-height: unset
    // max-width: unset
    // border-radius: 0
    // cursor: default
    // transform: unset
 } }    // top: unset

// fixed draggable poup
.dropup {
  &.hide {
    top: auto!important;
    right: auto!important;
    bottom: 0!important;
    left: 0!important;
    transform: none!important;
    height: auto!important;
    .dropup__close--hide,
    .dropup__close--line {
      display: none; }
    .dropup__close--show,
    .dropup__close--rect {
      display: block; } }
  &.closed {
    .dropup__mobile {
      .dropup__show {
        transform: rotateX(0deg); } } }
  &__wrapin {
    display: flex;
    @include r(767) {
      flex-direction: column; } }
  &__bold {
    font-size: 16px;
    margin-left: 20px;
    color: #0052A4;
    text-align: center;
    margin-bottom: 15px;
    margin-top: 20px;
    @include osb;
    @include r(767) {
      order: -1;
      margin-left: 0;
      font-size: 13px;
      margin-bottom: 20px; } }
  &__show {
    position: absolute;
    transition: all .3s ease;
    transform-origin: center;
    width: 15px;
    height: 15px;
    right: 20px;
    top: 35%;
    transform: rotateX(180deg); }
  &__desktop {
    text-transform: uppercase;
    color: #fff;
    font-size: 23px;
    @include osb;
    @include r(767) {
      display: none; } }
  &__mobile {
    display: none;
    @include r(767) {
      display: block;
      text-transform: uppercase;
      font-size: 16px;
      color: #fff; } }
  &__item {
    &--labdesk {
      @include r(767) {
        display: none; } }
    &--labmob {
      display: none;
      @include r(767) {
        display: list-item; } } }
  &__close {
    border: none;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    position: absolute;
    right: 2px;
    top: 1px;
    cursor: pointer;
    z-index: 1;
    &--show {
      display: none; }
    &--hide {
      display: block; }
    @include r(767) {
      // display: none!important
      top: 0; }
    &--line {
      right: 35px; }
    &--rect {
      right: 35px;
      top: 2px;
      display: none; }
    svg {
      path {
        transition: all .3s ease; } }
    &:hover {
      svg {
        fill: #fff;
        path {
          fill: #fff; } } } }
  &__title {
    text-transform: uppercase;
    margin-bottom: 10px;
    text-align: center;
    margin-top: 15px;
    font-size: 15px;
    color: #000;
    @include osb; }
  &__list {
    list-style-type: disc;
    padding-left: 20px;
    flex: 1 0 auto;
    li {
      margin-bottom: 4px; } }
  &__link {
    color: blue;
    text-transform: capitalize;
    text-decoration: underline!important; }
  &__top {
    padding: 10px 0;
    line-height: 26px;
    padding: 10px 64px;
    position: relative;
    text-align: center;
    width: 100%;
    background: linear-gradient(45deg, #ef7237 0%, #f69f4c 100%);
    @include osb;
    @include r(767) {
      border-color: transparent;
      padding: 3px 20px;
      margin-bottom: 0; } }
  &__content {
    display: block;
    padding: 0 20px;
    overflow: auto!important;
    height: calc(100% - 80px);
    @include r(767) {
      height: calc(100% - 58px); }
    @include e(767) {
      display: block; }
    &::-webkit-scrollbar {
        width: 0px;
        background: transparent; }
    &::-webkit-scrollbar-thumb {
        background: #FF0000; }
    @media all and (-ms-high-contrast:none) {
      height: auto; } }

  &__bottom {
    @include r(767) {
      margin-bottom: 30px; }
    .signup__btn {
      width: 40%;
      text-transform: uppercase;
      text-decoration: none!important;
      margin: 12px auto 35px;
      display: block;
      height: 52px;
      font-size: 15px;
      text-align: center;
      line-height: 51px;
      @include r(767) {
        width: 62%;
        height: 43px;
        line-height: 43px;
        font-size: 15px; } } } }

i[class^="icon-"] {
  display: inline-block; }

button[disabled] {
  opacity: .5; }

a {
  text-decoration: none!important; }

.hidden-scroll {
  overflow-y: hidden; }

.low-case {
  text-transform: lowercase; }

.hidden-r {
  display: none!important; }

.base-title {
  color: #1e5393;
  font-size: 35px;
  text-align: center;
  margin-bottom: 35px;
  text-transform: uppercase;
  font-weight: 900;
  @include osb;
  @include r(1024) {
    font-size: 30px; }
  @include r(768) {
    font-size: 22px;
    margin-bottom: 0; } }

ul {
  padding-left: 0;
  list-style: none; }

.container {
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  @include r(1200) {
    padding: 0 20px; }
  @include r(768) {
    padding: 0 15px; }
  @include r(480) {
    padding: 0 10px; } }

body {
  font-size: 14px;
  @include osreg; }

img {
  max-width: 100%; }

.hero {
  padding: 6% 0 3%;
  margin-bottom: 40px;
  .container {
    max-width: 1170px; } }

.smile {
  height: 340px;
  border-radius: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 340px;
  background: rgba(230, 64, 138, 0.7);
  font-size: 50px;
  border: 2px solid #fff;
  color: #1e5393;
  line-height: 1;
  z-index: 2;
  font-weight: 700;
  @include r(480) {
    width: 200px;
    height: 200px;
    font-size: 25px; }
  &::after {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 100%;
    transform: translateY(-10px);
    background: #e6fafc;
    z-index: -1; }
  @include ereg;
  @include r(1000) {
    margin: 0 auto;
    width: 300px;
    height: 300px;
    font-size: 42px; }
  &__pink {
    color: #ea4f85; }
  p {
    position: relative;
    top: -15px; } }

.features {
  margin-bottom: 20px;
  &__inner {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap; }
  &__or {
    font-size: 18px;
    margin-left: 10px;
    color: #1f5494;
    text-transform: uppercase;
    @include osb; }
  &__sign {
    margin-bottom: 60px;
    text-align: center; }
  .container {
    position: relative; }
  .icon-png-pick {
    position: absolute;
    left: -40px;
    top: -60%;
    transform: scale(.6); } }

.feature {
  border: 12px solid #c5d6e9;
  font-size: 25px;
  padding: 24px;
  max-width: 280px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  @include r(480) {
    font-size: 20px;
    padding: 20px; }
  @include ebi;
  &__title {
    color: #1e5393; }
  &__subtitle {
    color: #f6561c; }
  &__info {
    margin-left: 0;
    margin: 0 auto;
    position: relative;
    @include r(480) {
      margin-left: 0; }
    .icon-png-pick {
      position: absolute;
      z-index: 100;
      left: 50%;
      top: 20px;
      transform: translateX(-30%) scale(.6) rotate(45deg); } }
  &__icon {
    min-width: 38%;
    @include r(480) {
      transform: scale(.7); } }
  .signup__btn {
    font-size: 18px;
    padding: 0 30px;
    margin: 0 auto;
    svg {
      vertical-align: text-bottom;
      margin-right: 5px; } }
  .icon-png-pick {
    @include r(599) {
      display: none; } }
  &--first {
    border: none;
    padding: 0; } }

.zone {
  padding-top: 50px;
  background-image: linear-gradient(180deg, #e87921 0%, #ddab38 100%);
  padding-right: 130px;
  @include r(1240) {
    padding-right: 0px; }
  @include r(560) {
    padding-top: 5px; }
  .container {
    max-width: 1250px;
    display: flex; }
  &__title {
    font-weight: 900;
    letter-spacing: -2px;
    color: #f25f00;
    text-transform: uppercase;
    font-size: 70px;
    text-align: center;
    margin: 5% 2%;
    width: 80%;
    border-radius: 6px;
    @include osb;
    @include r(768) {
      font-size: 28px;
      text-align: center;
      letter-spacing: -1px; }
    span {
      font-size: 63px;
      &.zone__solutions {
        font-size: 40px;
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        &::after,&::before {
          content: '';
          flex: auto;
          height: 2px;
          top: 50%;
          background: #f25f00; }
        &::before {
          margin-right: 20px; }
        &::after {
          margin-left: 20px; }
        @include r(768) {
          font-size: 20px; }
        @include r(420) {
          font-size: 15px; } }
      @include r(1200) {
        font-size: 55px; }
      @include r(768) {
        font-size: 30px; }
      @include r(420) {
        font-size: 20px; } } }
  &__inner {
    background: #fff;
    max-width: 1155px;
    align-self: flex-end;
    display: flex;
    margin-top: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: auto;
    @include r(480) {
      margin-top: 6px; } }
  &__top {
    display: flex;
    align-self: flex-start;
    margin-left: 13%;
    flex-wrap: wrap;
    @include r(1128) {
      justify-content: center; }
    @include r(1024) {
      margin: 0 auto; }
    @include r(768) {
      margin: 0;
      align-self: center; } }
  &__list {
    color: #fff;
    padding-left: 40px;
    margin-bottom: 12%;
    @include r(1128) {
      margin-bottom: 30px; }
    @include r(1024) {
      padding-left: 0; }
    @include r(480) {
      padding-left: 0; }
    h3 {
      font-size: 30px;
      text-transform: uppercase;
      font-weight: 400;
      margin-bottom: 40px;
      @include r(1024) {
        font-size: 25px;
        text-align: center; } }
    li {
      font-size: 20px;
      position: relative;
      padding-left: 40px;
      color: #000;
      margin-bottom: 20px;
      @include osb;
      @include r(1024) {
        font-size: 18px; }
      @include r(560) {
        font-size: 12px; }
      @include r(480) {
        padding-left: 30px;
        font-size: 11px; }
      &:last-of-type {
        margin-bottom: 0; }

      i {
        vertical-align: middle;
        position: absolute;
        left: 7px;
        @include r(560) {
          margin-right: 10px;
          transform: scale(.8);
          top: -5px; } } } }
  &__img {
    position: relative;
    &::after {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 0;
      right: 0;
      max-height: 280px;
      height: 40px;
      background: radial-gradient(#333 0%, rgba(255, 255, 255, 0) 60%); }
    img {
      display: block;
      position: relative;
      max-height: 600px;
      max-width: 100%;
      z-index: 2; } } }

.zicons {
  &__svg {
    width: 90px;
    height: 100px;
    cursor: pointer;
    &--tooth {
      position: relative;
      left: 10px; }
    @include r(480) {
      width: 55px;
      height: auto; } }
  .base-title {
    @include r(480) {
      margin-bottom: 20px!important;
      max-width: 88%;
      margin: 0 auto; } }
  &__details {
    text-align: center;
    color: #0052a4;
    background: #b2e6ff;
    margin: 0 30px;
    padding: 10px;
    border-radius: 4px;
    color: #0052a4;
    font-weight: 500;
    letter-spacing: .6px;
    text-transform: capitalize;
    display: block;
    transition: all .3s ease;
    &:hover {
      background: #0052a4;
      color: #fff; } }
  &__item {
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 35px 0;
    justify-content: flex-start;
    margin-bottom: 30px;
    max-width: 200px;
    flex: 1 1 200px;
    position: relative;
    z-index: 2;
    overflow: hidden;
    background: #D1F0FF;
    transition: all .3s ease;
    &:hover {
      cursor: pointer;
      background: #e8f7ff;
      box-shadow: 3px 3px 0 0 #0052A4; }
    @include r(480) {
      max-width: 47%;
      width: 100%;
      padding-bottom: 20px;
      margin-bottom: 10px; } }
  &__number {
    color: #0052A4;
    font-size: 28px;
    position: absolute;
    left: 20px;
    top: 0;
    @include osb;
    @include r(480) {
      font-size: 13px; } }
  &__inner {
    width: 100%; }
  &__top {
    text-align: center;
    color: #fff;
    background: #0052A4;
    font-size: 21px;
    width: 100%;
    padding: 20px 5px;
    margin-bottom: 35px;
    @include osreg;
    @include r(480) {
      font-size: 14px;
      padding: 10px 5px; } }
  &__icon {
    min-height: 120px;
    position: relative;
    text-align: center;
    font-size: 36px;
    margin-bottom: 10px;
    @include r(560) {
      min-height: 80px; } }
  &__list {
    display: flex;
    max-width: 1635px;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    flex-wrap: wrap;
    @include r(768) {
      justify-content: space-around;
      margin: unset; }
    @include r(1024) {
      justify-content: space-around;
      margin: unset; } }
  &__title {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
    color: #0052A4;
    text-transform: uppercase;
    font-weight: 600;
    @include r(480) {
      font-size: 14px;
      padding: 0; } } }

.zicons__svg > g > path ,
.zicons__svg > g > circle,
.zicons__svg > g > line,
.zicons__svg > g > rect {
  stroke: #0052A4;
  stroke-miterlimit: 10;
  stroke-width: 2;
  fill: none; }
.zicons__svg > g > line,
.zicons__svg > g > rect {
  stroke-width: 1; }

.zicons__svg--filled {
  fill: #0052A4!important;
  stroke: none!important; }
.zicons__svg--fill_stroke {
  fill: none!important;
  stroke: #0052A4!important;
  stroke-width: 0.75; }
.zicons__svg--strw {
  fill: #fff!important;
  stroke: #0052A4!important;
  stroke-width: 0.25; }

.landing-page {
  .signup {
    top: 0;
    margin-bottom: 70px;
    @include r(480) {
      margin-bottom: 30px; } }
  .base-title {
    @include r(768) {
      margin-bottom: 30px;
      font-size: 18px; }
    @include r(480) {
      font-size: 18px;
      margin-bottom: 0; } }
  .zicons {
    padding: 0 20px;
    @include r(480) {
      margin-bottom: 20px;
      padding: 0; } }
  .zone {
    &__img {
      margin-right: -39px;
      @include r(1024) {
        display: none; }
      &::after {
        display: none; } }
    &__title {
      @include r(1354) {
        font-size: 70px; }
      @include r(1340) {
        font-size: 50px; }
      @include r(1245) {
        font-size: 45px; }
      @include r(1024) {
        text-align: center;
        font-size: 30px;
        text-transform: uppercase;
        margin: 5% 10px; } } }
  .icon-png-pick {
    opacity: 0.8; } }

.signup {
  background: #0052A4;
  color: #fff;
  padding: 20px;
  position: relative;
  z-index: 1;
  top: -60px;
  text-align: center;
  @include osb;
  @include r(1024) {
    padding: 20px; }
  @include r(768) {
    &__content {
      display: flex;
      flex: auto;
      flex-wrap: wrap;
      justify-content: space-around;
      >div {
        @include r(480) {
          width: 100%; } } } }
  @include r(480) {
    &__big {
      font-size: 25px; }
    &__bold {
      font-size: 30px; } }
  @include r(560) {
    top: 0;
    margin-bottom: 60px; }
  .container {
    max-width: 1250px; }
  &__reg {
    font-size: 15px;
    letter-spacing: 0px;
    font-weight: 100 !important;
    color: #ccc;
    @include r(480) {
      margin: 10px 0; } }
  &__top {
    font-size: 30px;
    display: flex;
    align-items: center;
    letter-spacing: -2px;
    justify-content: space-between;
    flex-wrap: wrap;
    max-width: 700px;
    margin: 0 auto;
    @include r(1024) {
      font-size: 25px; }
    @include r(900) {
      justify-content: space-around; } }
  &__rightblock {
    margin-right: 80px;
    @include r(768) {
      margin-right: 0; } }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left; }
  &__item {
    display: flex;
    align-items: center;
    &:first-child {}
    @include r(480) {
      justify-content: space-around;
      margin-right: 0; } }
  &__yellow {
    color: #fdd967;
    font-weight: 900;
    text-align: left;
    margin-right: 30px;
    letter-spacing: .3px;
    @include r(1024) {
      margin-right: 20px; } }
  &__big {
    font-weight: 700;
    font-size: 37px;
    letter-spacing: .3px;
    @include r(1024) {
      font-size: 35px; } }
  &__bold {
    font-weight: 700;
    font-size: 40px;
    @include r(1024) {
      font-size: 30px; } }
  &__launch {
    font-size: 43px;
    @include r(875) {
      font-size: 30px;
      margin-right: 0; }
    @include r(480) {
      font-size: 20px;
      letter-spacing: 0; }
    @include r(419) {
      margin-bottom: 10px; } }
  &__blue {
    color: #f3c31b;
    font-weight: 700;
    padding-right: 10px; }
  &__arrow {
    width: 60px;
    height: 60px;
    background: #fff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    bottom: -30px;
    transform: translateX(-50%); }
  &__btn {
    background: #e87921;
    border: none;
    outline: none;
    color: #fff;
    text-transform: capitalize;
    padding: 0 50px;
    font-size: 20px;
    height: 56px;
    line-height: 56px;
    border-radius: 50px;
    transition: all .3s ease;
    box-shadow: 1px 5px 20px 0px rgba(0, 0, 0, 0.29);
    @include r(1024) {
      font-size: 20px; }
    @include r(768) {
      padding: 0 20px;
      height: 40px;
      line-height: 40px;
      font-size: 14px; }
    @include osb;
    &:hover {
      background: #ff581f;
      cursor: pointer; } }
  &__text {
    @include r(1024); }
  .perkstable__stop {
    @include r(1024) {
      font-size: 23px; } } }

/* the slides */
.slick-slide {
  margin-left: 20px;
  outline: none!important;
  border: none!important; }
// &:nth-child(odd)
//     .proditem__inner
//         border-bottom: 5px solid #e87921
// &:nth-child(even)
//     .proditem__inner
//         border-bottom: 5px solid #1e5393

.btn-next,
.btn-prev {
  position: absolute;
  top: 50%;
  z-index: 100;
  border: none!important;
  outline: none!important;
  background: transparent;
  transform: translateY(-50%);
  &:hover {
    cursor: pointer; } }

.btn-next {
  right: -50px;
  @include r(1080) {
    right: 0; } }

.btn-prev {
  left: -50px;
  @include r(1080) {
    left: 0; } }

/* the parent */
.slick-loading .slick-list {
  background: #fff; }
// .slick-list
//   padding-right: 20px
//   &::after,
//   &::before
//     position: absolute
//     content: ''
//     top: 0
//     bottom: 0
//     width: 25px
//     z-index: 1
//   &::after
//     left: 0
//     background-image: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 100%)
//   &::before
//     right: 0
//     background-image: linear-gradient(-90deg, #fff 0%, rgba(255, 255, 255, 0) 100%)

// slick dots
.slick-dots {
  bottom: -45px; }
.slick-dots li button:before {
  content: '';
  width: 11px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 11px;
  background: #1e5393;
  border-radius: 11px; }

.slick-dots li.slick-active button:before {
  width: 17px;
  height: 17px;
  opacity: 1; }


// reviews begin
.revprod {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  &__item {
    width: 49%;
    display: flex;
    background: #d0e5f6;
    min-height: 220px;
    padding: 10px;
    margin-bottom: 20px;
    border-radius: 4px;
    @include r(768) {
      width: 100%; }
    @include r(480) {
      padding: 15px; }
    @include r(375) {
      flex-direction: column; } }
  &__img {
    height: 0;
    padding-bottom: 140%;
    width: 170px;
    border-radius: 4px;
    @include r(375) {
      width: 60%;
      padding-bottom: 83%;
      margin: 0 auto; } }
  &__doctor {
    color: #294b8c;
    font-size: 14px;
    text-align: left;
    @include osb;
    @include r(375) {
      margin-bottom: 5px; } }
  &__bg {
    display: flex;
    flex-direction: column;
    @include r(768) {
      margin-bottom: 15px; } }
  &__text {
    flex: auto;
    margin-left: 10px;
    background: #ffffff;
    color: #000000;
    padding: 10px;
    font-size: 14px;
    border-radius: 4px;
    @include r(375) {
      margin-left: 0; }
    p {
      margin-bottom: 10px; } } }
// reviews end

.products {
  margin-bottom: 45px;
  position: relative;
  padding-top: 10%;
  @include r(1024) {
    padding-top: 20%; }
  &::after {
    content: '';
    position: absolute;
    top: -120px;
    left: 0;
    right: 0;
    height: 200px;
    background: red;
    z-index: -1;
    transform: skewY(3deg);
    height: 200px;
    background: linear-gradient(90deg, #2b53a1 0%, #0881c6 50%, #2b53a1 100%);
    @include r(480) {
      top: -176px; } }
  .base-title {
    color: #f3842b;
    font-size: 45px;
    @include r(480) {
      margin-bottom: 20px; } }
  .container {
    max-width: 1250px;
    @include r(1320) {
      max-width: 1000px; } }
  &__button {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include r(480) {
      margin: 10px 0 0; }
    button {
      background: #e87921;
      border: none;
      outline: none;
      padding: 10px 50px;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      border-radius: 53px;
      height: 56px;
      box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.5);
      &:hover {
        cursor: pointer; }
      @include osb;
      @include r(768) {
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        padding: 0 20px; } }
    @include r(768) {
      margin: 30px 0;
      a {
        font-size: 14px;
        padding: 0px 40px; } } }
  &__subtitle {
    margin-top: 40px;
    margin-bottom: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include r(480) {
      margin: 20px 0 60px; }
    p {
      text-align: center;
      font-size: 18px;
      @include osreg; }
    a {
      text-align: center;
      margin-top: 10px;
      color: #1e5293;
      font-size: 17px;
      text-decoration: underline!important;
      @include osb; }

    &__name {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #fff;
      color: #d9762c;
      font-size: 16px;
      text-align: center;
      line-height: 1.56;
      padding: 15px 0;
      @include r(768) {
        bottom: -2px; } }
    &__text {
      color: #000;
      background: #fff;
      p {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        font-size: 13px;
        line-height: 1.65;
        font-weight: 100;
        padding: 35px 0;
        margin-right: 20px;
        @include r(768) {
          margin-right: 0;
          text-align: center;
          padding: 20px 10px; } } } } }

.subscribe__wrap {
  margin-bottom: 20px;
  @include r(1024) {
    width: 100%; } }

.form-error {
  color: red;
  flex: 1 auto;
  border: 1px solid red;
  word-break: break-all;
  min-height: 31px;
  line-height: 31px;
  margin: 10px;
  padding: 0 10px;
  border-radius: 4px;
  background: rgba(red, .2);
  @include osb; }

.revolution {
  &__top {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 2% 0 0;
    margin-bottom: -40px;
    background: #fff;
    overflow: hidden;
    position: relative;
    // clip-path: polygon(0px -102px, 100% 0px, 100% 100%, 0px 80%)
    &::before {
      content: '';
      position: absolute;
      bottom: -67px;
      left: -10px;
      right: 0;
      height: 200px;
      background: linear-gradient(173deg, #e87921 20%, #ddab38 73%);
      z-index: 100;
      transform: rotate(3deg);
      display: none;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: block; }
      @supports (-ms-ime-align:auto) {
        display: block; } } }
  &__title {
    margin: 120px 0 40px 0;
    color: #ffffff;
    text-align: center;
    color: #fff;
    font-size: 60px;
    line-height: 1.25;
    letter-spacing: -1.62px;
    margin-bottom: 70px;
    text-align: center;
    font-weight: 900;
    text-transform: uppercase;
    @include osb;
    @include r(480) {
      margin-top: 100px;
      font-size: 20px; }
    span {
      font-size: 46px;
      text-transform: uppercase;
      color: #0052a4;
      font-size: 60px;
      letter-spacing: -1.29px;
      @include r(480) {
        font-size: 20px; } }
    @include r(768) {
      font-size: 30px;
      text-align: center;
      letter-spacing: 0;
      span {
        font-size: 30px;
        letter-spacing: 0; } } }
  &__bottom {
    padding: 65px 0 120px;
    margin-bottom: 90px;
    overflow: hidden;
    position: relative;
    background-image: linear-gradient(32deg, #e87921 0%, #ddab38 100%);
    clip-path: polygon(0px 0px, 99.95% 114px, 100% 100%, 0px 80%);
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      padding-top: 0;
      clip-path: none; }
    @supports (-ms-ime-align:auto) {
      padding-top: 0;
      clip-path: none; }
    @include r(1024) {
      padding-bottom: 100px;
      margin-bottom: 30px; }
    @include r(768) {
      padding-top: 0;
      clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 90%); }
    @include r(480) {
      padding-top: 0; }
    // +r(560)
    //     clip-path: polygon(0 0, 100% 0, 100% 100%, 0 92%)
    &::after {
      content: '';
      position: absolute;
      bottom: -120px;
      left: 0;
      right: 0;
      height: 200px;
      background: transparent;
      z-index: 1;
      transform: skewY(4deg);
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        transform: rotate(4deg);
        background: #fff;
        left: -10px; }
      @supports (-ms-ime-align:auto) {
        transform: rotate(4deg);
        background: #fff;
        left: -10px; } }

    img {
      margin-top: 30px;
      @include r(768) {
        transform: scale(1.5); }
      @include r(480) {
        margin-top: 0; } } } }

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .revolution {
    &::after {
      background: #fff; }
    &__title {
      margin-top: 60px; } } }

@supports (-ms-ime-align:auto) {
  .revolution {
    &::after {
      background: #fff; }
    &__title {
      margin-top: 60px; } } }

.double {
  position: relative;
  z-index: 1;
  &__text,
  &__img {
    width: 50%;
    @include r(1115) {
      width: 100%; } }
  &__orange {
    color: #e87921; }
  &__btn {
    text-align: right;
    margin-top: 30px;
    @include r(480) {
      margin-top: 0; } }
  .signup__btn {
    margin-bottom: 60px;
    margin-right: 0; }
  &--top {
    margin-bottom: 135px;
    .base-title {
      text-align: left; }

    p {
      text-align: left; }

    @include r(1115) {
      margin-bottom: 60px; }
    .double__img {
      text-align: right;
      @include r(1115) {
        text-align: left; } } }
  &--bottom {
    margin-bottom: 60px;
    .base-title {
      text-align: right;
      @include r(1115) {
        text-align: left; } }
    .double__text {
      @include r(1115) {
        order: -1; } }
    p {
      text-align: right;
      @include r(1115) {
        text-align: left; } } }
  p {
    color: #333;
    font-size: 16px;
    margin-bottom: 20px;
    @include r(560) {
      font-size: 15px; }
    @include osreg; }
  .container {
    display: flex;
    flex-wrap: wrap; }
  .base-title {
    margin-bottom: 50px;
    @include r(560) {
      margin-bottom: 20px; } } }

.pick {
  h3 {
    margin-bottom: 0!important;
    padding: 20px 0;
    color: #F3842B;
    text-transform: uppercase;
    font-size: 70px;
    margin: 0 70px 0 130px;
    @include osb;
    @include r(1200) {
      margin: 0;
      font-size: 50px; }
    @include r(817) {
      font-size: 40px; }
    @include r(768) {
      font-size: 68px;
      margin: 0; }
    @include r(480) {
      font-size: 38px;
      margin: 0;
      padding: 0 0 20px; } }
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    @include r(560) {
      flex-direction: column; } }
  i {
    transform: scale(.8);
    position: relative;
    top: 20px;
    left: -5%;
    z-index: 10000;
    @include r(1200) {
      left: 0; }
    @include r(768) {
      top: 63px;
      display: none; }
    @include r(560) {
      transform: scale(.8); } } }

.section-table {
  padding: 3% 0 2% 0;
  position: relative;
  z-index: 3;
  background: linear-gradient(90deg, #2b53a1 0% , #0881c6 50% , #2b53a1 100% );
  @include r(480) {
    padding: 5% 0; }
  .container {
    max-width: 1000px;
    @include r(1155) {
      max-width: 83%; }
    @include r(767) {
      max-width: 100%; } }
  .signup__btn {
    margin: 0 auto;
    display: block;
    @include r(768) {
      margin-left: 0;
      margin: 0 auto; } }
  .signup {
    .container {
      max-width: 100%; }
    &__launch {
      @include r(875) {
        margin-bottom: 20px; } } } }

.reg-title {
  margin-bottom: 20px;
  font-size: 15px; }

.reg-inputgroup {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  flex-wrap: wrap;
  .subscribe__wrap {
    @include r(1024) {
      width: 100%; } }
  .subscribe__input {
    align-items: center;
    padding: 0;
    width: auto;
    align-items: center;
    @include r(1095) {
      width: 100%; }
    input {
      @include r(1024) {
        max-width: unset!important;
        margin-right: 0!important; } }
    label {
      margin-right: 10px;
      padding: 0; } } }
#login-form-ref {
  .reg-inputgroup {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    label {
      min-width: 75px; }
    .subscribe__wrap {
      width: 100%;
      max-width: 300px; } } }

#register-form-ref {
  display: none; }
.form-tabs {
  margin-bottom: 20px;
  .form-tabs-btn {
    background: #ffffff;
    outline: none!important;
    min-width: 122px;
    border-radius: 50px;
    height: 50px;
    padding: 0 30px;
    color: #0052A4;
    border: 2px solid #0052A4;
    transition: all .3s ease;
    &:hover, &.active {
      cursor: pointer;
      background: #0052A4;
      color: #fff; }
    @include osb; } }

.subscribe {
  background: linear-gradient(90deg, #2b53a1 0%, #0881c6 50%, #2b53a1 100%);
  padding: 0 0 30px 0;
  // display: none
  @include r(1024) {
    padding-bottom: 0; }
  &__container {
    padding: 0!important;
    @include r(1024) {
      max-width: 100%; } }
  .container {
    @include r(480) {
      padding: 0 10px 30px; } }
  &__col {
    width: 49%;
    border: 1px solid #bcbcbc;
    border-radius: 4px;
    background: #f8f8f8;
    padding: 20px 20px 5px 20px;
    margin-bottom: 20px;
    align-self: stretch;
    &--register {
      label {
        min-width: 103px; } }
    &--payment {
      label {
        min-width: 174px; } }
    &--billing {
      label {
        min-width: 92px; } }
    &--contacts {
      label {
        min-width: 132px; } }
    @include r(1240) {
      width: 100%; }
    .subscribe__wrap {
      width: 100%;
      &--row {
        display: flex;
        @include r(767) {
          flex-direction: column; }
        .subscribe__input {
          width: 50%;
          flex-wrap: wrap;
          margin-bottom: 0;
          @include r(767) {
            width: 100%; }
          select, input {
            flex: 1 0 60%;
            @include r(480) {
              flex: 1 auto; } } }
        .form-error {
          @include r(480) {
            width: 100%; } } } } }
  &__container {
    @include r(480) {
      padding: 0; } }
  &__title {
    font-size: 20px;
    text-align: left;
    margin-bottom: 20px;
    @include r(768) {
      text-align: center; } }
  &__signature {
    border-radius: 4px;
    width: 100%;
    resize: none;
    border: 1px solid #ccc;
    min-height: 150px; }
  &__month {
    margin-right: 10px;
    @include r(480) {
      margin: 10px 0 10px 0; } }
  &__slash {
    margin: 0 3px;
    font-size: 20px;
    @include r(767) {
      display: none; } }
  &__subtitle {
    font-size: 18px;
    color: #1e5393;
    margin-bottom: 18px;
    text-transform: uppercase;
    font-weight: 900;
    text-align: left;
    @include osb;
    @include r(768) {
      text-align: center; } }
  &__big-title {
    margin-bottom: 25px; }
  &__checks {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 25px;
    // disabled start
    &--disabled {
      color: #b2b2b2;
      .subscribe__title {
        color: #b2b2b2;
        span {
          color: #b2b2b2; } }
      .subscribe__check__label {
        background: transparent;
        border: 2px solid #b2b2b2;
        border-radius: 2px;
        width: 25px;
        height: 25px;
        cursor: pointer;
        display: block;
        flex-shrink: 0; } }
 }    // disabled end
  &__check {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    &:last-child {
      margin-bottom: 10px; }
    p {
      margin-left: 15px;
      font-size: 16px;
      font-weight: 700;
      span {
        text-decoration: line-through; } }
    @include r(768) {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 5px; }
      p {
        text-align: left;
        margin-right: 10px;
        font-size: 13px; } }
    &__radio {
      display: none;
      &:checked {
        ~ span {
          background: #1e5393; } } }
    &__label {
      background: transparent;
      border: 2px solid #1e5393;
      border-radius: 2px;
      width: 25px;
      height: 25px;
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all .5s ease;
      flex-shrink: 0;
      span {
        background: transparent;
        width: 70%;
        height: 70%;
        display: block;
        position: absolute; } } }
  &__hr {
    border-bottom: 1px solid #e4e4e4; }
  &__payment {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 30px;
    @include r(480) {
      flex-direction: column;
      align-items: baseline;
      padding: 10px 10px;
      margin: 0 0 10px; }
    .subscribe__check {
      margin-bottom: 0;
      margin-right: 15px;
      &:last-child {
        margin-right: 0; }
      @include r(768) {
        margin-right: 0; }
      @include r(480) {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0; } } } }
  &__secondname {
    width: 100%;
    font-size: 15px;
    justify-content: flex-start;
    font-weight: 600;
    margin-bottom: 15px;
    p {
      text-align: center; }
    @include r(768) {
      p {
        text-align: center; } } }
  &__inputs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @include r(768) {
      flex-direction: column;
      align-items: flex-start; } }
  &__input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    #card_number {
      flex: 1 0 31px;
      @include r(767) {
        width: auto;
        flex: auto; } }
    #subscr_year {
      @include r(767) {
        margin-bottom: 20px; } }
    .icon-png-checkGreen {
      margin-left: 15px; }
    &--card {
      @include r(767) {
        flex-direction: row!important;
        flex-wrap: wrap!important; } }
    &--date {
      justify-content: flex-end;
      @include r(767) {
        flex-direction: column; }
      label {
        min-width: unset; }
      input {
        max-width: 55px!important; }
      #subscr_month {
        margin-right: 0;
        @include r(767) {
          margin-bottom: 10px; } }
      #subscr_year {
        margin-right: 10px; } }
    &:last-child {
      margin-bottom: 0; }
    &:nth-child(even) {
      padding-right: 0; }
    @include r(768) {
      width: 100%;
      padding-right: 0;
      margin-bottom: 10px; }
    @include r(480) {
      flex-direction: column; }
    label {
      text-align: right;
      white-space: nowrap;
      padding-right: 10px;
      font-size: 12px;
      @include r(480) {
        font-size: 13px;
        min-width: unset!important;
        margin-bottom: 10px;
        text-align: center;
        margin-right: 0; }
      small {
        color: #6c6a6a;
        font-size: 10px;
        font-style: italic; } }
    input,
    select {
      max-width: unset;
      flex: 0 1 auto;
      height: 31px;
      max-height: 31px;
      border: 1px solid #d4d4d4;
      outline: none;
      border-radius: 4px;
      width: 100%;
      padding: 0 7px;
      font-family: OpenSansRegular, sans-serif;
      font-weight: 400;
      @include r(480) {
        max-width: unset;
        padding: 0 5px; } } } }

.small-launch {
  font-size: 13px;
  color: #000;
  text-shadow: none;
  font-size: 14px;
  @include r(480) {
    margin-bottom: 10px; } }

.perkstable {
  margin: 0 auto;
  margin-bottom: 30px;
  position: relative;
  &::after, &::before {
    content: '';
    position: absolute;
    @include r(767) {
      display: none; } }
  &::after {
    left: -40px;
    right: -40px;
    top: 60px;
    border-radius: 4px;
    bottom: 60px;
    background: #d1f0ff;
    z-index: -1;
    @include r(767) {
      left: -20px;
      right: -20px; } }
  &::before {
    left: -80px;
    right: -80px;
    top: 100px;
    border-radius: 4px;
    bottom: 100px;
    background: #98dcfd;
    z-index: -1;
    @include r(767) {
      left: -40px;
      right: -40px; } }
  &__inner {
    right: -40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    &:first-child {
      align-items: flex-start; }
    .low-case {
      font-size: 30px;
      @include r(480) {
        font-size: 17px; } } }
  &__innerrow {
    display: flex;
    align-items: center;
    min-height: 40px;
    small {
      padding: 10px 10px 6px;
      font-size: 13px;
      color: black;
      text-shadow: none; } }
  &__row {
    display: flex;
    &:first-child,
    &:last-of-type {
      min-height: 95px;
      .perkstable__cell {
        text-align: center;
        justify-content: center;
        font-size: 26px !important;
        font-weight: 700;
        padding: 0;
        @include osb;
        @include r(768) {
          font-size: 20px!important; }
        @include r(480) {
          font-size: 18px !important; }
        &--perks {}
        &--center {
          font-size: 19px; } } }
    &:last-of-type {
      .perkstable__cell {
        font-size: 30px!important; } }
    &:nth-child(2n) {
      .perkstable__cell {
        &:first-child {
          background: #e3eef9;
          font-size: 19px;
          @include r(480) {
            font-size: 14px; }
          @include r(768) {
            // background: #f2b889
 } } } }            // color: #004a71
    &:nth-child(2n + 1) {
      .perkstable__cell {
        &:first-child {
          background: #f0f1f1;
          font-size: 19px;
          @include r(480) {
            font-size: 14px; }
          @include r(768) {
            // background: #f2b889
 } } } }            // color: #004a71
    @include r(768) {
      flex-wrap: wrap; }
    &--bottom {
      .perkstable__cell {
        &:first-child,
        &:nth-child(2),
        &:nth-child(3) {
          background: #00669c!important;
          color: #fff;
          font-size: 19px;
          text-transform: uppercase;
          padding: 0 10px; }
        &:nth-child(3) {
          background: #e87921!important; }
        &:first-child {
          @include r(768) {
            .signup {
              margin-bottom: 0;
              padding: 20px; }
 } }            // display: none
        @include r(768) {
          font-size: 18px!important; } } } }

  &__cell {
    flex: 1;
    padding: 10px 0px 10px 20px;
    letter-spacing: -.5px;
    border: 1px solid rgba(255,255,255, .6);
    border-bottom-color: #ddd;
    border-right-color: #ddd;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    color: #00669c;
    @include osreg;
    @include r(768) {
      padding: 10px; }
    i {
 }      //transform: scale(1.5)
    &--member {
      border-radius: 4px 4px 0 0;
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        min-height: 95px; } }
    &--signup {
      border-radius: 0 0 4px 4px;
      display: block;
      overflow: hidden;
      padding: 0!important;
      .signup {
        margin-bottom: 0;
        &__launch {
          font-size: 33px;
          @include r(480) {
            font-size: 25px;
            margin-bottom: 15px; } }
        &__top {
          font-size: 23px;
          text-transform: capitalize;
          @include r(480) {
            font-size: 18px; } }
        &__bold {
          font-size: 28px; }
        .perkstable {
          &__stop {
            font-size: 23px; } }
        &__big {
          font-size: 30px;
          text-transform: lowercase; } } }
    &:first-child {
      flex: 1.3;
      justify-content: flex-start;
      @include r(768) {
        // width: 100%
        // flex: auto
        // text-align: center
 } }        // justify-content: center
    &:nth-child(2) {
      background: #e5e5e9;
      font-size: 17px;
      @include r(480) {
        font-size: 14px; } }
    &:nth-child(3) {
      background: #e5e6e7;
      font-size: 17px;
      @include r(480) {
        font-size: 14px; } }
    &--perks,
    &--free,
    &--member {
      font-size: 25px;
      text-transform: uppercase;
      font-weight: 700;
      color: #fff;
      @include r(768) {
        font-size: 18px; } }
    &--member,
    &--free {
      text-align: center; }
    &--free {
      background: #00669c!important; }
    &--member {
      background: #e87921!important; }
    &--perks {
      background: #c9e0f5!important;
      color: #00669c; }

    &--center {
      text-align: center;
      padding: 0 10px; }
    .member-only {
      margin-left: 10px; } }
  &__stop {
    position: relative;
    font-weight: 700;
    font-size: 33px;
    @include r(768) {
      font-size: 12px; }
    &::after,
    &::before {
      content: '';
      position: absolute;
      height: 2px;
      left: 0;
      top: 50%;
      right: 0;
      background: red; }
    &::after {
      transform: rotate(15deg);
      opacity: 0; }
    &::before {
      transform: rotate(-15deg); } } }

// quotes
.quotes {
  float: left;
  width: 55px;
  svg {
    path {
      fill: #1e5495; } } }
// quotes

// doc begin
.doc {
  font-size: 0px;
  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    max-height: 550px; }
  @supports (-ms-ime-align:auto) {
    max-height: 550px;
    height: 100%;
    margin-bottom: 7%; }
  .container {
    max-width: 1050px; }
  &__img, .doc__text {
    display: inline-block;
    vertical-align: middle; }
  &__img {
    width: 30%;
    @include r(480) {
      width: 100%; }
    img {
      @include r(480) {
        max-width: 50%;
        margin: 0 auto 30px;
        display: block; } } }
  &__text {
    width: 70%;
    font-size: 20px;
    line-height: 1.5;
    @include r(767) {
      font-size: 14px; }
    @include r(480) {
      width: 100%; }
    p {
      margin-bottom: 25px;
      @include r(480) {
        margin-bottom: 16px; } } }
  &__name {
    color: #1e5495;
    text-align: right;
    font-size: 30px;
    @include osb;
    @include r(767) {
      font-size: 20px; }
    @include r(480) {
      margin-bottom: 50px!important; } } }
// doc end

// account form begin
.accountform {
  box-shadow: 0 0 20px 0px rgba(51, 51, 51, 0.5);
  border-radius: 6px;
  overflow: hidden;
  padding: 0 40px;
  background: #fff;
  border-top: 5px solid #0f4e8a;
  color: #363636;
  text-align: center;
  @include r(768) {
    padding: 0 20px; }
  @include r(480) {
    padding: 0 10px; }
  &__title {
    font-size: 24px;
    color: #363636;
    text-align: center; }
  &__subtitle {
    text-align: left;
    line-height: 1.65;
    font-size: 16px;
    margin-bottom: 30px;
    @include r(768) {
      font-size: 13px;
      margin-bottom: 20px; } }
  &__login {
    color: #0f4e8a;
    text-decoration: underline!important;
    @include osb; } }

.accform {
  color: #151515;
  font-size: 12px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ccc;
  padding: 20px;
  background: #fcfcfc;
  border-radius: 10px;
  margin-bottom: 40px;
  hr {
    opacity: 0; }
  @include r(1024) {
    width: 100%; }
  @include r(768) {
    margin-bottom: 20px; }
  @include r(480) {
    padding: 10px; } }

// account form end

// main slider
.mslider {
  margin-bottom: 100px;
  &__slide {
    height: 0;
    width: 100%;
    display: flex;
    color: #ffffff;
    position: relative;
    font-size: 30px;
    padding-bottom: 7%;
    margin: 0;
    @include osb;
    h3 {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #1e5393;
      padding: 10px 30px; } } }
.msidebar {
  flex: 0 0 265px;
  margin-right: 35px;
 }  // width: 100%
.base-orange-title {
  background: linear-gradient(45deg, #ef7237 0% ,  #f69f4c 100%);
  height: 55px;
  font-size: 18px;
  line-height: 55px;
  padding-left: 30px;
  border-radius: 6px 6px 0 0;
  text-transform: uppercase;
  color: #ffffff;
  @include osb;
  span {
    margin-left: 5px; } }
.sidelist {
  background: #fff;
  padding: 0 20px 20px;
  border-radius: 0 0 6px  6px;
  &__text {
    margin-right: 20px; }
  a {
    color: #383838;
    font-size: 14px;
    text-transform: capitalize;
    transition: all .3s ease;
    word-break: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    &:hover {
      color: #f6561c; }
    .sidelist__arrow {
      transition: all .2s ease;
      transform-origin: center; }
    &.active {
      color: #f6561c;
      @include osb;
      .sidelist__arrow {
        transform: rotateX(180deg);
        path {
          fill: #f6561c; } } } }
  &__link {
    padding: 20px 0;
    border-bottom: 1px solid #d7d7d7;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include osb;
    span {
      flex: 1; } } }

.sidelistchild {
  display: none;
  &--first {
    padding: 15px 0px 15px 20px;
    border-bottom: 1px solid #d7d7d7;
    a {
      padding: 7px 0; } }

  &--second {
    padding-left: 30px;
    a {
      padding: 10px 0; } }
  &__item {
    a {
      display: flex;
      align-items: center;
      justify-content: space-between; } } }

.mprod {
  max-width: 165px;
  padding: 15px;
  background: #ffffff;
  border-radius: 6px;
  &__name {
    text-align: center;
    font-size: 12px;
    color: #1f1f1f;
    @include osb; }
  &__img {
    img {
      display: block;
      margin: 0 auto; } } }
.bg-grad {
  background: linear-gradient(90deg, #b7d9ff 0%, #ddf6ff 100%);
  padding: 35px 0 100px 0;
  margin-bottom: -60px; }

.two-columns-grid {
  display: flex; }

.mprodgrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -15px -15px 0;
  &__cell {
    margin: 15px; } }

// thank
.thank {
  background: #ffffff;
  padding: 30px;
  border-radius: 6px;
  max-width: 800px;
  margin: 0 auto;
  font-size: 16px;
  line-height: 1.4;
  p {
    margin-bottom: 20px; }
  &__title {
    font-size: 30px;
    color: #000000;
    margin-bottom: 20px;
    @include osb; }
  &__bottom {
    font-weight: 700; } }

// common
.icon-png-suppliers2, .icon-png-payment2, .icon-png-dashboard2 , .icon-png-management2, .icon-png-invoice2 , .icon-png-tooth2 {
  transform: scale(0.7); }
.slick-list::before {
  right: -1px; }
.slick-list::after, .slick-list::before {
  width: 50px; }
@import './components/footer';
