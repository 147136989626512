@import './common/mixins';


.mheader {
    &__middle {
        display: flex;
        margin-bottom: 15px;
        .zicons__svg {
            width: 20px;
            height: 20px; } }
    &__bottom {
        display: flex;
        margin-bottom: 15px; } }
.topline {
    background: linear-gradient(180deg, #fe9500 0%, #f6581b 100%);
    color: #fff;
    font-size: 16px;
    padding: 8px 0;
    margin-bottom: 20px;
    box-shadow: 0 0 14px 0px rgba(0, 0, 0, 0.52);
    &__inner {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between; }
    &__block {
        display: flex; }
    .ml-3 {
        margin-left: 14px; }
    .ml-2 {
        margin-left: 7px; } }

.customers {
    display: flex;
    &__block {
        width: 23px;
        height: 21px;
        display: inline-flex;
        border: 1px solid #000;
        color: #000;
        align-items: center;
        justify-content: center; }
    &__text {
        color: #000; } }

.callus {
    background: #0f4e8a;
    padding: 7px 20px;
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__italic {
        font-style: italic; }
    &__phone {
        @include osb;
        font-size: 20px; } }

.acc {
    position: relative;
    display: flex;
    align-items: center;
    z-index: 3;
    &:hover {
        cursor: pointer; }
    &::after {
        content: '';
        width: 1px;
        position: absolute;
        top: -2px;
        height: 27px;
        background: #fff;
        opacity: 0.5;
        right: -17px; }
    &.opened {
        .drop-menu {
            opacity: 1;
            visibility: visible;
            transform: translateY(0px);
            z-index: 2; } } }

.logo-2 {
    max-width: 374px;
    display: block;
    img {
        display: block;
        width: 100%;
        max-width: 374px; } }

.drop-menu {
    background: #fff;
    position: absolute;
    top: 145%;
    z-index: 1;
    min-width: 105px;
    transform: translateY(2px);
    padding: 5px 15px;
    border-radius: 4px;
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.1);
    white-space: nowrap;
    transition: all .3s ease;
    opacity: 0;
    visibility: hidden;
    &::after {
        content: '';
        width: 0;
        position: absolute;
        top: -7px;
        left: 50%;
        transform: translateX(-50%);
        height: 0;
        border-style: solid;
        border-width: 0 4px 7px 4px;
        border-color: transparent transparent #ffffff transparent; }
    li {
        padding: 5px 0;
        border-bottom: 1px solid #ddd;
        &:last-of-type {
            border-bottom: none; } }
    &__link {
        color: #0f4e8a;
        font-size: 13px;
        @include osb;
        &--logout {
            color: tomato; } } }

.cartshop {
    display: flex;
    align-items: center;
    margin-left: 5px;
    &__items {
        // color: #000
        @include osb; } }

.nav {
    display: flex;
    align-items: center;
    margin-left: 60px;
    position: relative;
    left: -20px;
    align-self: flex-end;
    margin-bottom: 10px;
    flex: auto;
    &__link {
        color: #1e5393;
        position: relative;
        height: 45px;
        padding-left: 22px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        flex: 1;
        &.active {
            color: #fff;
            .svg-bg {
                path {
                    fill: #0f4e8a; } }
            .nav__icon {
                path {
                    fill: #fff; } } }
        &:first-child {
            z-index: 6; }
        &:nth-child(2) {
            z-index: 5; }
        &:nth-child(3) {
            z-index: 4; }
        &:nth-child(4) {
            z-index: 3; }
        &:nth-child(5) {
            z-index: 2; }
        &:nth-child(6) {
            z-index: 1; }
        @include osb;
        &:hover {
            .svg-bg {
                path {
                    fill: #d4e8ff;
                    stroke: #d4e8ff; } } }
        &::after {
            content: '';
            position: absolute;
            bottom: -2px;
            height: 4px;
            left: 0;
            right: -18px;
            background: #23a8f0; }
        svg {
            margin-right: 10px;
            &.svg-bg {
                margin-right: 0;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: -1;
                path {
                    transition: all .3s ease; } } } } }

.msearch {
    max-width: 373px;
    flex: 373px;
    width: 100%;
    height: 40px;
    display: flex;
    border-radius: 6px;
    overflow: hidden;
    border: .5px solid #b8b8b8;
    &__input {
        padding: 0 20px;
        outline: none;
        flex: auto;
        border: none;
        &:placeholder {
            color: #9e9e9e;
            font-size: 20px;
            @include osb; } }
    &__btn {
        border: none;
        outline: none;
        background: #f6561c;
        width: 55px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .3s ease;
        &:hover {
            background: #dc4812;
            cursor: pointer; } } }

.mlist {
    display: flex;
    min-height: 57px;
    flex: auto;
    margin-left: 40px;
    li {
        flex: auto;
        display: flex;
        background: #ecf5ff;
        position: relative;
        transition: all .3s ease;
        &:hover {
            background: #d5f0fd; }
        &:first-child {
            border-radius: 6px 0 0 6px; }
        &:last-of-type {
            border-radius: 0 6px 6px 0; }
        >a {
            color: #084e8c;
            font-size: 15px;
            flex: auto;
            display: flex;
            justify-content: space-around;
            align-items: center;
            text-transform: uppercase;
            @include osb; } }
    &__arrow {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%); }
    &__dropdown {
        &:hover {
            .homedrop-wrap {
                opacity: 1;
                visibility: visible; } } } }
.homedrop-wrap {
    position: absolute;
    top: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease;
    text-align: center;
    z-index: 1;
    width: 100%;
    left: 0; }
.homedrop {
    min-width: 163px;
    position: relative;
    border-radius: 6px;
    background: #fff;
    border-top: 6px solid #1e5393;
    box-shadow: 0 5px 10px 0px rgba(0,0,0,.2);
    margin-top: 5px;
    padding: 20px 10px;
    &::after {
        content: '';
        position: absolute;
        top: -12px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 5px 6px 5px;
        border-color: transparent transparent #1e5393 transparent; }
    &__link {
        text-transform: capitalize;
        font-size: 16px;
        color: #1e5393;
        @include osb; } }
