// media mixin
@mixin r($width) {
	@media (max-width: $width + "px") {
		@content; } }



// font-face mixin
// =font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg)
@mixin font-face($name, $path, $weight: null, $style: null, $exts:  ttf ) {
    $src: null;

    $extmods: (eot: "?", svg: "#" + str-replace($name, " ", "_"));

    $formats: (otf: "opentype", ttf: "truetype");

    @each $ext in $exts {
        $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
        $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
        $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma); }

    @font-face {
        font-family: quote($name);
        font-style: $style;
        font-weight: $weight;
        src: $src; } }

// media mixin
@mixin e($width) {
    @media (min-width: $width + "px") {
        @content; } }

// short mixin to include fonts
@mixin mb {
    font-family: MontserratBlack, sans-serif; }
@mixin mr {
    font-family: MontserratRegular, sans-serif; }
@mixin ebi {
    font-family: ExoBoldItalic, sans-serif; }
@mixin eb {
    font-family: ExoBold, sans-serif; }
@mixin ereg {
    font-family: ExoRegular, sans-serif; }
@mixin henb {
    font-family: HelveticaNeueBold, sans-serif; }
@mixin osb {
    font-family: OpenSansBold, sans-serif; }
@mixin osreg {
    font-family: OpenSansRegular, sans-serif;
    font-weight: 400; }

// How to use
// body
// 	line-height: 1.4
// 	+mb
