.footer-landing {
	.fotr__title {
		margin-bottom: 0;
		border-bottom: none; } }
.page-footer {
	background-image: linear-gradient(90deg, #23a8f0 0%, #1e5293 100%);
	backface-visibility: hidden;
	// background: #1e5293
	clip-path: polygon(0 0, 100% 8%, 100% 100%, 0 100%);
	padding: 100px 0 20px;
	position: relative;
	&::after {
		content: '';
		position: absolute;
		top: -120px;
		left: 0;
		right: 0;
		height: 200px;
		background: transparent;
		z-index: 1;
		transform: skewY(4deg); }
	@include r(768) {
		clip-path: polygon(0 0, 100% 3%, 100% 100%, 0 100%);
		padding-top: 100px; }
	.container {
		max-width: 1280px;
		padding: 0 20px; } }
@supports (-ms-ime-align:auto) {
	.page-footer {
		&::after {
			background: #fff; }
		&.footer-landing {
			clip-path: none;
			&::after {
				display: none; } } } }
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	.page-footer {
		&::after {
			background: #fff; }
		&.footer-landing {
			clip-path: none;
			&::after {
				display: none; } } } }

.formsub {
	background: #fff;
	align-self: flex-start;
	border-radius: 8px;
	box-shadow: 0px 8px 21px 0px rgba(0, 0, 0, 0.13);
	padding: 60px 45px 50px;
	@include r(1376) {
		width: 100%;
		margin-bottom: 40px; }
	@include r(560) {
		padding: 35px 26px 40px; }
	&__title {
		color: #2c2c2c;
		font-size: 31px;
		letter-spacing: -1px;
		margin-bottom: 20px;
		@include r(560) {
			font-size: 24px; }
		@include osb; }
	&__subtitle {
		color: #4d4d4d;
		font-size: 19px;
		margin-bottom: 50px;
		font-weight: 400;
		@include r(560) {
			font-size: 15px;
			margin-bottom: 20px; }
		@include osreg; }
	input {
		background: transparent;
		border: none;
		max-width: 400px;
		width: 100%;
		outline: none!important;
		border-bottom: 3px solid #6791f3;
		height: 50px;
		margin-bottom: 45px;
		font-size: 22px;
		color: #8c8c8c;
		&::placeholder {
			color: #8c8c8c;
			font-size: 22px; } }
	&__btn {
		height: 57px;
		font-size: 20px;
		background: #fb794d;
		border: none;
		outline: none;
		letter-spacing: -1px;
		padding: 0 50px;
		border-radius: 60px;
		color: #fff;
		transition: all .3s ease;
		@include osb;
		&:hover {
			cursor: pointer;
			background: #ff581f; } } }

.footer-top,
.fotrlinks {
	display: flex; }

.footer-top {
	flex-wrap: wrap;
	@include r(560) {
		margin-bottom: 0; } }

.fotrlinks {
	justify-content: space-between;
	flex-wrap: wrap; }

.fotr {
	flex: auto; }

.fotr,
.fotrlist a {
	color: #fff; }

.fotr {
	&__title {
		border-bottom: 1px solid #9bc3fb;
		margin-bottom: 35px;
		font-size: 22px;
		padding-bottom: 30px;
		@include r(560) {
			font-size: 17px; }
		@include osreg;
		.fort__sub {
			margin-left: 25px; } }
	&__phone {
		padding-left: 10px;
		font-size: 33px;
		color: #fff;
		@include osreg;
		@include r(560) {
			font-size: 20px; } } }


.fotrlist {
	margin-bottom: 40px;
	@include r(560) {
		width: 50%; }
	li {
		margin-bottom: 10px;
		&:first-child {
			margin-bottom: 20px;
			font-weight: 700;
			font-size: 17px; }
		a {
			font-size: 15px;
			font-weight: 400;
			@include r(480) {
				font-size: 13px; }
			@include osreg;
			&:hover {
				color: #ace1ff; } } } }

.footer-bottom {
	color: #fff;
	font-size: 22px;
	padding: 0 20px;
	.container {
		border-top: 1px solid #9ac2fb;
		padding: 20px 0 0;
		display: flex;
		flex-wrap: wrap;
		max-width: 1250px;
		align-items: center;
		justify-content: space-around;
		@include r(560) {
			padding: 25px 0 0; } }
	p {
		margin: 15px auto 15px 30px;
		font-size: 16px;
		@include r(560) {
			font-size: 14px;
			margin-left: 0;
			text-align: center; } }
	a {
		margin-left: 10px;
		@include r(560) {
			margin-left: 0; } }
	i {
		@include r(560) {
			transform: scale(.7); } }
	.icon-png-facebook,
	.icon-png-linkedin {
		transform: scale(.6); } }

.popup {
	position: fixed;
	background: #fff;
	padding: 45px;
	line-height: 1.6;
	top: 50%;
	left: 50%;
	max-height: 600px;
	height: 90%;
	color: #333;
	font-size: 14px;
	transform: translate(-50%, -50%);
	opacity: 0;
	visibility: hidden;
	border-radius: 20px;
	z-index: 100000;
	overflow: hidden;
	max-width: 600px;
	width: 90%;
	transition: all .3s ease;
	@include r(480) {
		padding: 30px; }
	h4 {
		margin-bottom: 30px;
		text-align: center;
		font-size: 20px; }
	strong {
		margin-right: 10px; }
	&__title {
		&--second {
			margin: 20px 0 10px 0!important; } }
	&.opened {
		opacity: 1;
		visibility: visible; }
	&__close {
		position: absolute;
		right: 20px;
		top: 30px;
		background: transparent;
		background-color: transparent;
		border: none;
		outline: none;
		&:hover {
			cursor: pointer; }
		@include r(480) {
			transform: scale(.7);
			right: 10px;
			top: 15px; } }
	&__content {
		overflow: auto;
		height: 100%; }
	&__text {
		p {
			margin-bottom: 10px; } } }

.popup-overlay, .popup-overlay-drag {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #000;
	opacity: 0;
	visibility: hidden;
	z-index: 1000;
	transition: all .3s ease;
	&.opened {
		opacity: .7;
		visibility: visible; } }
.payment-icon {
	margin-left: 10px;
	@include r(560) {
		margin-left: 0; } }


