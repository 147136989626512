%svg-common {
	background: url("../images/icons/sprite.svg") no-repeat;
}

.icon-dashboard {
	@extend %svg-common;
	background-position: 0 0;
	width: 49px;
	height: 49px;
}

.icon-dashboard1 {
	@extend %svg-common;
	background-position: 0 15.669870995388582%;
	width: 50.419px;
	height: 49.298px;
}

.icon-invoice {
	@extend %svg-common;
	background-position: 0 31.20046138864114%;
	width: 44.697px;
	height: 44.697px;
}

.icon-management {
	@extend %svg-common;
	background-position: 0 45.60743401174391%;
	width: 42.952px;
	height: 46.262px;
}

.icon-payment {
	@extend %svg-common;
	background-position: 0 60.886585187027016%;
	width: 66.277px;
	height: 48.302px;
}

.icon-search {
	@extend %svg-common;
	background-position: 0 71.42857142857143%;
	width: 25px;
	height: 26px;
}

.icon-suppliers {
	@extend %svg-common;
	background-position: 0 84.71337579617834%;
	width: 47.017px;
	height: 48px;
}

.icon-tooth {
	@extend %svg-common;
	background-position: 0 99.96434392828036%;
	width: 39.183px;
	height: 47.888px;
}

