.page-header {
	@include ereg; }

.headertop {
	background: #f6561c;
	color: #fff;
	padding: 6px 0;
	&__centerlogo {
		margin-bottom: 20px;
		.headertop__check {
			color: #1e5393;
			@include osb; } }
	.container {
		display: flex;
		align-items: baseline;
		justify-content: space-between;
		flex-wrap: wrap;
		@include r(768) {
			justify-content: center; } }
	&__check {
		font-size: 16px;
		@include r(1200) {
			font-size: 18px; }
		@include r(560) {
			font-size: 12px;
			display: flex;
			align-items: center; }
		i.icon-png-check {
			@include r(560) {
				transform: scale(.6); } } }
	&__col {
		font-size: 20px;
		display: flex;
		align-items: baseline;
		.icon-png-how {
			position: relative;
			top: 3px; }
		&--count {
			background: #1e5393;
			border-radius: 25px;
			color: #fff;
			padding: 10px 30px;
			@include r(1200) {
				font-size: 17px; }
			@include r(768) {
				margin: 5px auto 0; }
			@include r(480) {
				padding: 5px 10px;
				font-size: 14px; } } }
	&__contact,
	&__exclamation {
		a {
			color: #ffffff; }
		.info-icon {
			position: relative;
			top: 2px; }
		@include r(768) {
			display: none; } }
	&__phone {
		font-size: 20px;
		margin-left: 15px; } }

.mob-infoblock {
	display: none;
	width: 100%;
	background: #f6561c;
	margin-bottom: 20px;
	text-align: center;
	border-radius: 8px;
	color: #fff;
	padding: 10px;
	@include r(768) {
		display: block;
		* {
			display: block; } }
	&-works {
		background: transparent;
		color: #1e5393;
		p {
			font-size: 15px; }
		a,
		.info-icon {
			display: inline-block;
			vertical-align: middle;
			color: #1e5393; } } }

.mobbuttons {
	display: none;
	padding: 10px 20px;
	&__fone {
		color: #000;
		@include osb; }
	.logo {
		max-width: 150px;
		img {
			margin: 0; } }
	@include r(768) {
		display: flex;
		justify-content: space-between;
		align-items: center; }
	button {
		outline: none;
		border: none; }
	&__close {
		display: none; }
	&__search {
		display: none;
		background: #f6561c;
		border-radius: 4px;
		width: 40px;
		height: 40px;
		align-items: center;
		justify-content: center;
		&:hover {
			cursor: pointer; }
		>* {
			pointer-events: none; }
		&.opened {
			svg.icon-search {
				display: none; }
			.mobbuttons__close {
				display: block; } }
		.icon-search {
			margin: 0 auto; } }
	&__menu {
		background-color: transparent;
		background: transparent;
		>* {
			pointer-events: none; } } }

.headercount {
	font-size: 0px;
	margin-right: 10px;
	&__item {
		border: 1px solid #fff;
		font-size: 20px;
		width: 25px;
		display: inline-block;
		text-align: center;
		height: 25px;
		line-height: 22px;
		@include eb;
		&:first-child,
		&:nth-child(2),
		&:nth-child(3) {
			border-right: none; }
		@include r(768) {
			font-size: 16px; }
		@include r(480) {
			font-size: 14px;
			line-height: 18px;
			width: 20px;
			height: 20px; } } }

.headermiddle {
	background: #1e5393;
	padding: 8px 0;
	@include r(768) {
		padding: 0; }
	&__nav {
		display: flex;
		flex-wrap: wrap;
		@include r(840) {
			width: 100%;
			justify-content: space-around; }
		@include r(768) {
			padding-right: 9px; }
		a {
			color: #fff;
			font-size: 16px;
			display: flex;
			align-items: center;
			margin-left: 35px;
			&:first-child {
				margin-left: 0; }
			@include r(768) {
				font-size: 14px;
				margin-left: 0; }
			@include r(320) {
				font-size: 10px; }
			span {
				margin-left: 10px;
				@include r(768) {
					margin-left: 0; }
				@include r(480) {
					margin-left: -5px; } } }
		i {
			transform: scale(.8);
			@include r(768) {
				transform: scale(.5); } } }
	&__search {
		font-size: 0px;
		display: flex;
		flex: auto;
		@include r(840) {
			width: 100%; }
		@include r(768) {
			position: absolute;
			left: 0;
			right: 0;
			width: 98%;
			margin: 0 auto;
			z-index: 1;
			opacity: 0;
			visibility: hidden;
			transition: all .3s ease;
			&.opened {
				opacity: 1;
				visibility: visible; } }
		input {
			height: 35px;
			max-width: 480px;
			width: 100%;
			border: none;
			outline: none;
			font-size: 20px;
			padding: 3px 20px 6px 20px;
			border-radius: 4px 0 0 4px;
			font-size: 20px;
			vertical-align: middle;
			@include r(840) {
				max-width: unset; }
			&::placeholder {
				color: #9e9e9e;
				font-size: 20px; } }
		button {
			height: 35px;
			vertical-align: middle;
			width: 55px;
			background: #f6561c;
			border-radius: 0 4px 4px 0;
			border: none;
			outline: none;
			transition: all .3s ease;
			&:hover {
				cursor: pointer;
				background: #ff4400; } } }
	.container {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
		@include r(480) {
			padding: 0; } } }

.headerbottom {
	@include r(767) {
		display: none; }
	&__nav {
		display: flex;
		margin-right: -20px;
		flex-wrap: wrap;
		@include r(1113) {
			width: 100%;
			justify-content: space-around; }
		@include r(768) {
			padding-top: 20px; }
		a {
			color: #1e5393;
			padding: 0 20px;
			min-height: 105px;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 16px;
			transition: all .3s ease;
			position: relative;
			border-bottom: 6px solid transparent;
			@include r(768) {
				min-height: unset;
				width: 100%;
				margin-bottom: 10px;
				font-size: 15px; }
			&:hover {
				background: #e6fafc;
				border-bottom-color: #f6561c;
				&::after {
					opacity: 1; } }
			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				transform: translateX(-50%);
				transition: all .3s ease;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 4.5px 5px 4.5px;
				border-color: transparent transparent #f6561c transparent;
				z-index: 1;
				opacity: 0; } } }
	.container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center; }
	.logo {
		@include r(768) {
			display: none; } }
	&__contact {
		font-weight: 500;
		font-size: 21px;
		color: #0051b3;
		@include r(1025) {
			margin: 0 auto 30px; }
		@include r(768) {
			display: none; } }
	&__phone {
		font-size: 30px;
		color: #0051b3; } }

.logo {
	display: flex;
	align-items: center;
	overflow: hidden;
	max-width: 410px;
	img {
		margin: 20px auto;
		max-width: 100%;
		width: 100%;
		position: relative;
		@include r(768) {
			left: unset; } } }
